import React from "react"

class DashTable extends React.Component {
    
    constructor(props) {
		super(props);
    }
   
  
  
    render() {
        return (

                    <div>
						<div class="card">
						{(this.props.showTitle) && (
                        <div class="card-header border-transparent">
                            <h3 class="card-title">{this.props.titulo}</h3>

							<div class="card-tools">
							<button type="button" class="btn btn-tool" data-card-widget="collapse">
								<i class="fas fa-minus"></i>
							</button>
							<button type="button" class="btn btn-tool" data-card-widget="remove">
								<i class="fas fa-times"></i>
							</button>
							</div>
						</div>
                        )}
					
						<div class="card-body p-10">
							<div class="table table-striped dataTable table-responsive">
							<table class="table table-responsive m-0 cv-table" id={'cv-table-'+this.props.id}>
								<thead>
								<tr>
                                {this.props.titulos.map(titulo => (
                                    <th>{titulo}</th>    
                                ))}								
								</tr>
								</thead>
								<tbody>
								{this.props.data.map(row => (
									<tr>
										{row.map(el => (
                                        <td>{el}</td>
                                        ))}										
									</tr>
								))}
								
								</tbody>
							</table>
							</div>
					
						</div>
					
						</div>
					
					</div>
            
        )
  }
}

export default DashTable




