import React from "react"

class DashInfoBox extends React.Component {
  render() {
    return (
              <div class="info-box">
                <span style={{height:'122px',width:'122px'}} className={`info-box-icon ${this.props.class} elevation-1`}><i style={{fontSize:'64px'}} className={`fas ${this.props.icon}`}></i></span>

                <div class="info-box-content">
                  <span class="info-box-text"><h4 style={{fontSize:'22px'}}>{this.props.title}</h4></span>
                  <span class="info-box-number">
                    <h4 style={{fontSize:'42px'}}><b>{this.props.value}</b></h4>
                    <small> {this.props.unit}</small>
                  </span>
                </div>            
              </div>
    )
  }
}

export default DashInfoBox