import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DashContainer from "./components/DashContainer"
import DashContainerFull from "./components/DashContainerFull"

const Main = () => {
  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' component={DashContainer}></Route>      
      <Route exact path='/full' component={DashContainerFull}></Route>      
    </Switch>
  );
}

export default Main;