import React from "react"

class DashHeader extends React.Component {
    
    constructor(props) {
		super(props);
    }
    
    init() {
        this.props.onInitButtonHeader()
    }

    logout() {
        this.props.onLogoutButtonHeader()
    }
  
  
    render() {
    return (
        <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Carnes Validadas / Tablero de Control</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Principal</a></li>
                <li class="breadcrumb-item active">{this.props.actualPage}</li>
                &nbsp;<small><button onClick={this.init.bind(this)}>Refresh</button></small>
                <small><button onClick={this.logout.bind(this)}>Desconectar</button></small>
                </ol>
            </div>
            </div>
        </div>
        </div>
    )
  }
}

export default DashHeader