import React from 'react';
import {Line} from 'react-chartjs-2';
import CsvDownloader from 'react-csv-downloader';

class LineChartDouble extends React.Component {

    

    constructor(props) {
        super(props);
    }


    render() {
                
        
        const data = {
            labels: this.props.labels,
            datasets: [
                {
                label: this.props.titulo,
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: this.props.data,
                },
                {
                    label: this.props.titulo_second,
                    fill: true,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(95,92,92,0.4)',
                    borderColor: 'rgba(95,192,92,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(95,92,92,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(95,92,92,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.props.data_second,
                },
            ]
            };
            
            return (                                
                <div>
                    <br/>
                <Line height={100} data={data} />
                <br/>
                <CsvDownloader
        filename="descarga"
        separator=","
        wrapColumnChar=""
        columns={this.props.dataToCSV.columns}
        datas={this.props.dataToCSV.datas}
        text="DESCARGAR" />
                </div>
            );
                    
    };
}


export default LineChartDouble;

