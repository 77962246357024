import React from "react"

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import DashInfoBox from "./DashInfoBox";
import DashHeader from "./DashHeader";
import DashTable from "./DashTable";

import Leaflet from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import LineChartContainer from "./LineChartContainer";
import LineChartDoubleContainer from "./LineChartDoubleContainer";
Leaflet.Icon.Default.imagePath =
'../node_modules/leaflet'



delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

//const defaultUrl = 'http://localhost:7001'
const defaultUrl = 'https://kontrolpanelo.carnesvalidadas.com'

class DashContainer extends React.Component {  	
	constructor(props) {
		super(props);
		this.state = {
			animales_count:"100",
			establecimientos: [],			
			establecimientosToTable: {data:[],titles:[]},
			
			lat: -34.118562,
			lng: -59.216906,
			zoom: 6,
			logged: localStorage.getItem( 'userLogged' ) || false,
			user: localStorage.getItem( 'userLoggedUser' ) || "",
			password: localStorage.getItem( 'userLoggedPassword' ) || "",

			acumuladoAnimales: {
				labels: [1,2,3],
				data:[100,200,300],
				dataToCSV:{columns:[],datas:[]}
			},
			acumuladoUsuarios: {
				labels: [1,2,3],
				data:[100,200,300],
				dataToCSV:{columns:[],datas:[]}
			},
			acumuladoEstablecimientos: {
				labels: [1,2,3],
				data:[100,200,300],
				dataToCSV:{columns:[],datas:[]}
			},
			acumuladoEventos: {
				labels: [1,2,3],
				data:[100,200,300],
				dataToCSV:{columns:[],datas:[]}
			},

		}
		//this.init();
		this.login();
	}

	loginUserChange = function(event) {
		this.setState({user:event.target.value})
	}

	loginPasswordChange = function(event) {
		this.setState({password:event.target.value})
	}

	login = function(event) {

		
		var username = this.state.user;
		var password = this.state.password;
		
		var token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
		let self = this;

		fetch(defaultUrl+'/login', {
	    	  method: 'POST', // or 'PUT'
	    	  //body: JSON.stringify({}), // data can be `string` or {object}!
	    	  headers:{
	    	    //'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
				'Authorization': `Basic ${token}`
			  }
	    	  //'mode': 'no-cors',		        
	    	}).then(function(response)
			{
			 if(response.status!==200)
			  {
				self.setState({logged:false})
				localStorage.setItem( 'userLogged', false ) 
				localStorage.setItem( 'userLoggedUser', "" )
				localStorage.setItem( 'userLoggedPassword', "" )
				if (self.state.user!='' || self.state.password!='') {
					alert("usuario o password incorrectos.");
				}
			  } else {
				self.init();
				self.setState({logged:true})
				localStorage.setItem( 'userLogged', true )				
				localStorage.setItem( 'userLoggedUser', self.state.user )
				localStorage.setItem( 'userLoggedPassword', self.state.password )				
			  }
			})
		   
	}

	logout = function(event) {
		this.setState({logged:false})
		localStorage.setItem( 'userLogged', false ) 
		localStorage.setItem( 'userLoggedUser', "" )
		localStorage.setItem( 'userLoggedPassword', "" )				
		window.location.reload();
	}

	init() {

		var username = this.state.user
		var password = this.state.password
		
		
		var token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

		fetch(defaultUrl+'/establecimientos_v2', {
	    	  method: 'POST', // or 'PUT'
	    	  //body: JSON.stringify({}), // data can be `string` or {object}!
	    	  headers:{
	    	    //'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
				'Authorization': `Basic ${token}`
			  }
	    	  //'mode': 'no-cors',		        
	    	}).then(response => {
	    		return response.json();
	            })
	            .then(data => this.bindEstablecimientos(data))
				.catch(error => console.log(error));
				
		fetch(defaultUrl+'/animales_count', {
					method: 'POST', // or 'PUT'
					//body: JSON.stringify({}), // data can be `string` or {object}!
					headers:{
					  //'Content-Type': 'application/json',
					  //'Access-Control-Allow-Origin': '*',					  
					  'Authorization': `Basic ${token}`
					},					
					//'mode': 'no-cors',		        
				  }).then(response => {
					  return response.json();
					  })
					  .then(data => this.bindAnimales(data))
					  .catch(error => console.log(error));
					  
		fetch(defaultUrl+'/animals_per_week_get', {
					method: 'GET', // or 'PUT'
					//body: JSON.stringify({}), // data can be `string` or {object}!
					headers:{
					  //'Content-Type': 'application/json',
					  //'Access-Control-Allow-Origin': '*',					  
					  'Authorization': `Basic ${token}`
					},					
					//'mode': 'no-cors',		        
				  }).then(response => {
					  return response.json();
					  })
					  .then(data => this.bindAnimalesPorSemana(data))
					  .catch(error => console.log(error));

		fetch(defaultUrl+'/users_per_week_get', {
						method: 'GET', // or 'PUT'
						//body: JSON.stringify({}), // data can be `string` or {object}!
						headers:{
						  //'Content-Type': 'application/json',
						  //'Access-Control-Allow-Origin': '*',					  
						  'Authorization': `Basic ${token}`
						},					
						//'mode': 'no-cors',		        
					  }).then(response => {
						  return response.json();
						  })
						  .then(data => this.bindUsuariosPorSemana(data))
						  .catch(error => console.log(error));

		fetch(defaultUrl+'/facilities_per_week_get', {
						method: 'GET', // or 'PUT'
						//body: JSON.stringify({}), // data can be `string` or {object}!
						headers:{
						  //'Content-Type': 'application/json',
						  //'Access-Control-Allow-Origin': '*',					  
						  'Authorization': `Basic ${token}`
						},					
						//'mode': 'no-cors',		        
					  }).then(response => {
						  return response.json();
						  })
						  .then(data => this.bindEstablecimientosPorSemana(data))
						  .catch(error => console.log(error));
							  
		fetch(defaultUrl+'/events_per_week_get', {
							method: 'GET', // or 'PUT'
							//body: JSON.stringify({}), // data can be `string` or {object}!
							headers:{
							  //'Content-Type': 'application/json',
							  //'Access-Control-Allow-Origin': '*',					  
							  'Authorization': `Basic ${token}`
							},					
							//'mode': 'no-cors',		        
						  }).then(response => {
							  return response.json();
							  })
							  .then(data => this.bindEventosPorSemana(data))
							  .catch(error => console.log(error));
	}

	bindAnimalesPorSemana(result) {
		const columnsToCSV = [{
            id: 'first',
            displayName: 'Año - Semana'
          }, {
            id: 'second',
            displayName: 'Cantidad Acumulada'
		  }];
		const dataToCSV = [];
		
		let labels = [];
		let data = [];
		for (var a in result) {
			labels.push("("+a+")");
			data.push(result[a]);
			dataToCSV.push({first:a,second:result[a]});
		}
		this.setState({acumuladoAnimales:{labels:labels,data:data,dataToCSV:{columns:columnsToCSV,datas:dataToCSV}}})
	}

	bindUsuariosPorSemana(result) {
		const columnsToCSV = [{
            id: 'first',
            displayName: 'Año - Semana'
          }, {
            id: 'second',
            displayName: 'Cantidad Acumulada'
		  }];
		const dataToCSV = [];
		
		let labels = [];
		let data = [];
		for (var a in result) {
			labels.push("("+a+")");
			data.push(result[a]);
			dataToCSV.push({first:a,second:result[a]});
		}
		this.setState({acumuladoUsuarios:{labels:labels,data:data,dataToCSV:{columns:columnsToCSV,datas:dataToCSV}}})
	}

	bindEstablecimientosPorSemana(result) {
		
		const columnsToCSV = [{
            id: 'first',
            displayName: 'Año - Semana'
          }, {
            id: 'second',
            displayName: 'Cantidad Acumulada'
		  }];
		const dataToCSV = [];

		let labels = [];
		let data = [];
		for (var a in result) {
			labels.push("("+a+")");
			data.push(result[a]);
			dataToCSV.push({first:a,second:result[a]});
		}
		this.setState({acumuladoEstablecimientos:{labels:labels,data:data,dataToCSV:{columns:columnsToCSV,datas:dataToCSV}}})
	}

	bindEventosPorSemana(result) {
		
		const columnsToCSV = [{
            id: 'first',
            displayName: 'Año - Semana'
          }, {
            id: 'second',
            displayName: 'Cantidad Acumulada'
		  }];
		const dataToCSV = [];
		
		let labels = [];
		let data = [];
		for (var a in result) {
			labels.push("("+a+")");
			data.push(result[a]);
			dataToCSV.push({first:a,second:result[a]});
		}
		this.setState({acumuladoEventos:{labels:labels,data:data,dataToCSV:{columns:columnsToCSV,datas:dataToCSV}}})
	}

	bindAnimales(data) {		
		this.setState({animales_count:data[0].n})
	}

	bindEstablecimientos(data) {		
		
		
		var dataToTable = [];
		for (var a in data) {
			var rowTemp = [];
			rowTemp = [data[a].compania,data[a].establecimiento,data[a].animales,data[a].eventos]
			dataToTable.push(rowTemp)
		}
		var titulosToTable = ['Companía','Establecimiento','Animales','Eventos']
		this.setState({establecimientosToTable:{data:dataToTable,titles:titulosToTable}})
		
		
		this.setState({establecimientos:data})
		this.setState({establecimientos_count:data.length})
		var cantidadEventos = 0;
		for (var a in data) {
			cantidadEventos+=data[a].eventos;
		}
		this.setState({eventos_count:cantidadEventos})
	}
	


	render() {
		const position = [this.state.lat, this.state.lng] 
		return (		   
		
		<div class="content-wrapper">		

			<DashHeader actualPage="Tablero de Control" onInitButtonHeader={this.init.bind(this)} onLogoutButtonHeader={this.logout.bind(this)}/>

			<section class="content">      
			<div class="container-fluid">
			
		  
		  <div>
		   {(this.state.logged==false) && (
			<div>
			Usuario <input type="textbox" onChange={this.loginUserChange.bind(this)}></input>&nbsp;
			Password <input type="password" onChange={this.loginPasswordChange.bind(this)}></input>&nbsp;<button onClick={this.login.bind(this)}>Ingresar</button>
			</div>
		   )}
		   {(this.state.logged) && (
			<div>
			{(false) && (
			<div class="row">
				<div class="col-12 col-sm-6 col-md-3">
					<div class="form-group pull-right">
						<label>Filtro por Rango de Fechas:</label>
						<div class="input-group">
							<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="far fa-calendar-alt"></i>
							</span>
							</div>
							<input type="text" class="form-control float-right" id="filtro_rango_fechas"/>
							<span class="input-group-append">
								<button type="button" class="btn btn-info btn-flat">Filtrar</button>
							</span>
						</div>					
					</div>
				</div>
			</div>)}
			
			<div class="row">
				<div class="col-12 col-sm-12 col-md-4">
					<div class="col-12 col-sm-12 col-md-12">
						<DashInfoBox value={this.state.animales_count} icon="fa-key" class="bg-info" title="Tokens / Animales"/>
					</div>				

					<div class="clearfix hidden-md-up"></div>

					<div class="col-12 col-sm-12 col-md-12">              
						<DashInfoBox value={this.state.establecimientos_count} icon="fa-map-marker" class="bg-success" title="Establecimientos"/>			                    
					</div>
					
					<div class="col-12 col-sm-12 col-md-12">              
						<DashInfoBox value={this.state.eventos_count} icon="fa-file-import" class="bg-warning" title="Eventos"/>			                    				              
					</div>
				</div>
				<div class="col-12 col-sm-12 col-md-8">
						<div>
							<div class="card">
							<div class="card-header border-transparent">
								<h1 class="card-title">Ubicación de los Establecimientos</h1>

								<div class="card-tools">
								<button type="button" class="btn btn-tool" data-card-widget="collapse">
									<i class="fas fa-minus"></i>
								</button>
								<button type="button" class="btn btn-tool" data-card-widget="remove">
									<i class="fas fa-times"></i>
								</button>
								</div>
							</div>
						
							<div class="card-body p-0">
							
							<Map center={position} zoom={this.state.zoom} style={{height : '400px'}}>
								<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								{this.state.establecimientos.map(establecimiento => (
									<Marker position={establecimiento.location.coordinates}>
									<Popup>
										{establecimiento.establecimiento}
									</Popup>
									</Marker>		        		
		       					))}																
							</Map>

							</div>
							</div>
						</div>							
					</div>				
			</div>
			
			<div class="card">				
				<div class="card-header border-transparent">
					<h3 class="card-title">Acumulados Por Semana</h3>

					<div class="card-tools">
					<button type="button" class="btn btn-tool" data-card-widget="collapse">
						<i class="fas fa-minus"></i>
					</button>
					<button type="button" class="btn btn-tool" data-card-widget="remove">
						<i class="fas fa-times"></i>
					</button>
					</div>
				</div>
				<div class="card-body p-10" style={{paddingTop:0}}>				
					<Tabs defaultActiveKey="usuarios_establecimientos" id="uncontrolled-tab-example">
						
						<Tab eventKey="usuarios_establecimientos" title="Usuarios / Establecimientos">
							<LineChartDoubleContainer showTitle={false} labels={this.state.acumuladoUsuarios.labels} data={this.state.acumuladoUsuarios.data} data_second={this.state.acumuladoEstablecimientos.data} dataToCSV={this.state.acumuladoUsuarios.dataToCSV} titulo="Acumulado de Usuarios" titulo_second="Acumulado de Establecimientos"></LineChartDoubleContainer>
						</Tab>
						{(false) && (
						<Tab eventKey="usuarios" title="Usuarios">
							<LineChartContainer showTitle={false} labels={this.state.acumuladoUsuarios.labels} data={this.state.acumuladoUsuarios.data} dataToCSV={this.state.acumuladoUsuarios.dataToCSV} titulo="Acumulado de Usuarios"></LineChartContainer>
						</Tab>
						)}
						{(false) && (
						<Tab eventKey="establecimientos" title="Establecimientos">
							<LineChartContainer showTitle={false} labels={this.state.acumuladoEstablecimientos.labels} data={this.state.acumuladoEstablecimientos.data} dataToCSV={this.state.acumuladoEstablecimientos.dataToCSV} titulo="Acumulado de Establecimientos"></LineChartContainer>
						</Tab>
						)}
						<Tab eventKey="animales" enabled title="Animales">
							<div class="col-12 col-sm-12 col-md-12">
								<LineChartContainer showTitle={false} labels={this.state.acumuladoAnimales.labels} data={this.state.acumuladoAnimales.data} dataToCSV={this.state.acumuladoAnimales.dataToCSV} titulo="Acumulado de Animales"></LineChartContainer>
							</div>
						</Tab>
						
						<Tab eventKey="eventos" title="Eventos">
							<LineChartContainer showTitle={false} labels={this.state.acumuladoEventos.labels} data={this.state.acumuladoEventos.data} dataToCSV={this.state.acumuladoEventos.dataToCSV} titulo="Acumulado de Eventos"></LineChartContainer>
						</Tab>
					</Tabs>
				</div>
			</div>
			
			
			<div class="row">
				<div class="col-12 col-sm-12 col-md-12" style={{display : 'none'}}>

					<DashTable showTitle={true} titulo='Listado Consolidado por Establecimiento' titulos={this.state.establecimientosToTable.titles} data={this.state.establecimientosToTable.data}/>
					
					</div>
					
				</div>
				</div>
				)}
			</div>

			</div>
			</section>
			
		</div>
		
		 );
		}	
}
export default DashContainer
