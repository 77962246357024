import React from "react"
import DashInfoBox from "./DashInfoBox";
import DashHeader from "./DashHeader";
import DashTable from "./DashTable";
import parse from 'html-react-parser';

import Leaflet from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
Leaflet.Icon.Default.imagePath =
'../node_modules/leaflet'

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

//const defaultUrl = 'http://localhost:7001'
const defaultUrl = 'https://kontrolpanelo.carnesvalidadas.com'

class DashContainerFull extends React.Component {  	
	constructor(props) {
		super(props);
		this.state = {
            
            animals: {count:0,dataToTable:{data:[],titles:[],showTitle:false}},
			companies: {count:0,dataToTable:{data:[],titles:[],showTitle:false}},
			surrogates: {count:0,dataToTable:{data:[],titles:[],showTitle:false}},
			facilities: {count:0,dataToTable:{data:[],titles:[],showTitle:false}},
			users: {count:0,dataToTable:{data:[],titles:[],showTitle:false}},
			facilitiesGroupByType: [],
			eventsGroupByType:[],
			companiesWithoutFacilities: 0,
			usersWithoutTokens: 0,
			eventsCount: 0,
            
            ///////
            animales_count:"100",
			establecimientos: [],			
			establecimientosToTable: {data:[],titles:[],showTitle:true},
			
			lat: -34.118562,
			lng: -59.216906,
			zoom: 4,
			logged: localStorage.getItem( 'userLogged' ) || false,
			user: localStorage.getItem( 'userLoggedUser' ) || "",
			password: localStorage.getItem( 'userLoggedPassword' ) || "",

		}
		//this.init();
		this.filterEmail = React.createRef();
		this.fechaCorteMetricas = React.createRef();

		this.initTables = false;
		this.showAnimals = true;
		this.childKeyAnimals = 0;
		this.childKeyCompanies = 0;
		this.childKeyFacilities = 0;
		this.childKeySurrogates = 0;
		this.childKeyUsers = 0;
		
		this.pendingAnimals = false;
		this.pendingCompanies = false;
		this.pendingFacilities = false;
		this.pendingSurrogates = false;
		this.pendingUsers = false;
		this.login();

		
	}

	loginUserChange = function(event) {
		this.setState({user:event.target.value})
	}

	filterEmailChange = function(event) {
		this.setState({filterEmail:event.target.value})
	}
	
	loginPasswordChange = function(event) {
		this.setState({password:event.target.value})
	}

	login = function(event) {

		
		var username = this.state.user;
		var password = this.state.password;
		
		var token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
		let self = this;

		if (username!='kontrolpanelo') {
			self.setState({logged:false})
			localStorage.setItem( 'userLogged', false ) 
			localStorage.setItem( 'userLoggedUser', "" )
			localStorage.setItem( 'userLoggedPassword', "" )
			window.location.href='/';
			return;
		}

		fetch(defaultUrl+'/login', {
	    	  method: 'POST', // or 'PUT'
	    	  //body: JSON.stringify({}), // data can be `string` or {object}!
	    	  headers:{
	    	    //'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
				'Accept': 'application/json',
       		    'Content-Type': 'application/json',
				'Authorization': `Basic ${token}`
			  }
	    	  //'mode': 'no-cors',		        
	    	}).then(function(response)
			{
			 if(response.status!==200)
			  {
				self.setState({logged:false})
				localStorage.setItem( 'userLogged', false ) 
				localStorage.setItem( 'userLoggedUser', "" )
				localStorage.setItem( 'userLoggedPassword', "" )
				if (self.state.user!='' || self.state.password!='') {
					alert("usuario o password incorrectos.");
				}
			  } else {
				self.init();
				self.setState({logged:true})
				localStorage.setItem( 'userLogged', true )				
				localStorage.setItem( 'userLoggedUser', self.state.user )
				localStorage.setItem( 'userLoggedPassword', self.state.password )				
			  }
			})
		   
	}

	logout = function(event) {
		this.setState({logged:false})
		localStorage.setItem( 'userLogged', false ) 
		localStorage.setItem( 'userLoggedUser', "" )
		localStorage.setItem( 'userLoggedPassword', "" )				
		window.location.reload();
	}

	resetFilter() {
		this.filterEmail.current.value = "";
		this.init();
	}

	init() {
		
		++this.childKeyAnimals;
		++this.childKeyCompanies;
		++this.childKeyFacilities;
		++this.childKeySurrogates;
		++this.childKeyUsers;

		var username = this.state.user
		var password = this.state.password
		
        var token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
		
		this.pendingAnimals = true;
		this.pendingCompanies = true;
		this.pendingFacilities = true;
		this.pendingSurrogates = true;
		this.pendingUsers = true;
		
		fetch(defaultUrl+'/animals_get', {
            method: 'POST', // or 'PUT'
            body: JSON.stringify({"filterEmail":this.filterEmail.current.value}), // data can be `string` or {object}!
            headers:{
              //'Content-Type': 'application/json',
			  //'Access-Control-Allow-Origin': '*',
			  'Accept': 'application/json',
       		  'Content-Type': 'application/json',
              'Authorization': `Basic ${token}`
            }
            //'mode': 'no-cors',		        
          }).then(response => {
              return response.json();
              })
              .then(data => this.bindAnimals(data))
              .catch(error => console.log(error));

        fetch(defaultUrl+'/companies_get', {
            method: 'POST', // or 'PUT'
            body: JSON.stringify({"filterEmail":this.filterEmail.current.value}), // data can be `string` or {object}!
            headers:{
              //'Content-Type': 'application/json',
			  //'Access-Control-Allow-Origin': '*',
			  'Accept': 'application/json',
       		  'Content-Type': 'application/json',
              'Authorization': `Basic ${token}`
            }
            //'mode': 'no-cors',		        
          }).then(response => {
              return response.json();
              })
              .then(data => this.bindCompanies(data))
			  .catch(error => console.log(error));

		fetch(defaultUrl+'/surrogates_get', {
				method: 'POST', // or 'PUT'
				body: JSON.stringify({"filterEmail":this.filterEmail.current.value}), // data can be `string` or {object}!
				headers:{
				  //'Content-Type': 'application/json',
				  //'Access-Control-Allow-Origin': '*',
				  'Accept': 'application/json',
       		  	  'Content-Type': 'application/json',
				  'Authorization': `Basic ${token}`
				}
				//'mode': 'no-cors',		        
			  }).then(response => {
				  return response.json();
				  })
				  .then(data => this.bindSurrogates(data))
				  .catch(error => console.log(error));
			  
		fetch(defaultUrl+'/facilities_get', {
				method: 'POST', // or 'PUT'
				body: JSON.stringify({"filterEmail":this.filterEmail.current.value}), // data can be `string` or {object}!
				headers:{
				  //'Content-Type': 'application/json',
				  //'Access-Control-Allow-Origin': '*',
				  'Accept': 'application/json',
       		  	  'Content-Type': 'application/json',
				  'Authorization': `Basic ${token}`
				}
				//'mode': 'no-cors',		        
			  }).then(response => {
				  return response.json();
				  })
				  .then(data => this.bindFacilities(data))
				  .catch(error => console.log(error));

		fetch(defaultUrl+'/users_get', {
					method: 'POST', // or 'PUT'
					body: JSON.stringify({"filterEmail":this.filterEmail.current.value}), // data can be `string` or {object}!
					headers:{
					  //'Content-Type': 'application/json',
					  //'Access-Control-Allow-Origin': '*',
					  'Accept': 'application/json',
       		  		  'Content-Type': 'application/json',
					  'Authorization': `Basic ${token}`
					}
					//'mode': 'no-cors',		        
				  }).then(response => {
					  return response.json();
					  })
					  .then(data => this.bindUsers(data))
					  .catch(error => console.log(error));

		fetch(defaultUrl+'/events_get', {
						method: 'POST', // or 'PUT'
						body: JSON.stringify({"filterEmail":this.filterEmail.current.value}), // data can be `string` or {object}!
						headers:{
						  //'Content-Type': 'application/json',
						  //'Access-Control-Allow-Origin': '*',
						  'Accept': 'application/json',
       		  			  'Content-Type': 'application/json',
						  'Authorization': `Basic ${token}`
						}
						//'mode': 'no-cors',		        
					  }).then(response => {
						  return response.json();
						  })
						  .then(data => this.bindEvents(data))
						  .catch(error => console.log(error));

		fetch(defaultUrl+'/establecimientos_v2', {
	    	  method: 'POST', // or 'PUT'
	    	  body: JSON.stringify({"filterEmail":this.filterEmail.current.value}), // data can be `string` or {object}!
	    	  headers:{
	    	    //'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
				'Accept': 'application/json',
       		    'Content-Type': 'application/json',
				'Authorization': `Basic ${token}`
			  }
	    	  //'mode': 'no-cors',		        
	    	}).then(response => {
	    		return response.json();
	            })
	            .then(data => this.bindEstablecimientos(data))
				.catch(error => console.log(error));
					  		
	}

	
	wait(show) {
		if (show) {
			eval("$('#wait').modal('show');");
		} else {
			eval("setTimeout(function(){$('#wait').modal('hide');},1000)");
		}
	}
	
	exportarExcel(type) {
		
		this.wait(true);
		alert("Aguarde, este proceso puede demorar algunos segundos.");

		console.log(type);
		var username = this.state.user;
		var password = this.state.password;
		
        var token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
		
		
		fetch(defaultUrl+'/'+type+'_excel_get', {
			method: 'POST', // or 'PUT'
			body: JSON.stringify({"filterEmail":this.filterEmail.current.value}), // data can be `string` or {object}!
			headers:{
			//'Content-Type': 'application/json',
			//'Access-Control-Allow-Origin': '*',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Basic ${token}`
			}
			//'mode': 'no-cors',		        
		}).then(res => {
			return res.blob();
		}).then(blob => {
			const href = window.URL.createObjectURL(blob);
			const a = this.linkRef.current;
			a.download = type+'.xlsx';
			a.href = href;
			a.click();
			a.href = '';
			this.wait(false);
		}).catch(err => {console.error(err); this.wait(false);});
		
	}

	metricas() {
		
		alert("Aguarde, este proceso puede demorar algunos segundos.");
		this.wait(true);
		
		var username = this.state.user;
		var password = this.state.password;
		
        var token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
		fetch(defaultUrl+'/metricas_excel_get', {
			method: 'POST', // or 'PUT'
			body: JSON.stringify({fechaCorte:this.fechaCorteMetricas.current.value}), // data can be `string` or {object}!
			headers:{
			//'Content-Type': 'application/json',
			//'Access-Control-Allow-Origin': '*',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Basic ${token}`
			}
			//'mode': 'no-cors',		        
		}).then(res => {
			return res.blob();
		}).then(blob => {
			const href = window.URL.createObjectURL(blob);
			const a = this.linkRef.current;
			a.download = 'metricas.xlsx';
			a.href = href;
			a.click();
			a.href = '';			
			this.wait(false);
			
		}).catch(err => {console.error(err); this.wait(false);});
	}

	fullExcel() {
		
		alert("Aguarde, este proceso puede demorar algunos segundos.");
		this.wait(true);

		var username = this.state.user;
		var password = this.state.password;
		
        var token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
		
		
		fetch(defaultUrl+'/fulltables_excel_get', {
			method: 'POST', // or 'PUT'
			body: JSON.stringify({}), // data can be `string` or {object}!
			headers:{
			//'Content-Type': 'application/json',
			//'Access-Control-Allow-Origin': '*',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Basic ${token}`
			}
			//'mode': 'no-cors',		        
		}).then(res => {
			return res.blob();
		}).then(blob => {
			const href = window.URL.createObjectURL(blob);
			const a = this.linkRef.current;
			a.download = 'fulltables.xlsx';
			a.href = href;
			a.click();
			a.href = '';
			this.wait(false);
		}).catch(err => {console.error(err); this.wait(false);});
		
	}

	bindAnimals(data) {		
		
		var dataToTable = [];		
		var contadorEventos = 0;
		for (var a in data) {
			var rowTemp = [];
			rowTemp = [data[a].type,data[a].specie,data[a].productionSystem,data[a].gender,data[a].createdAt,data[a].createdBy,data[a].totalEvents]
			dataToTable.push(rowTemp)
		}
		
		var titulosToTable = ['Tipo','Especie','Sistema de Producción','Sexo','Fecha Creación','Creado Por','Total Eventos']		
		
		this.pendingAnimals = false;	
		
		this.setState({
            animals:
            {
                count:data.length,
                dataToTable:{data:dataToTable,titles:titulosToTable,showTitle:false}
            }
        });
		
											
		
		eval("$('#cv-table-animals').DataTable({'paging'      : true,'lengthChange': false,'searching'   : true,'ordering'    : true,'info'        : true,'autoWidth'   : false});");
		
		
        
	}
	
	bindEvents(data) {		
		var eventsTypes = [];
		for (var a in data) {
			eventsTypes.push(data[a].eventType)
		}
		console.log(eventsTypes);
		var reduceEvents = eventsTypes.reduce((p, c) => {
			var name = c;
			if (!p.hasOwnProperty(name)) {
			  p[name] = 0;
			}
			p[name]++;
			return p;
		  }, {})
		
		var groupByEvents = [];
		for (var b in reduceEvents) {
			groupByEvents.push({type:b,count:reduceEvents[b]});
		}
		this.setState({
            eventsGroupByType: groupByEvents,
            eventsCount: data.length,
        });


		
        
    }
    
    bindCompanies(data) {		
		var dataToTable = [];
		var contadorCompaniasSinEstablecimiento = 0;
		var contadorCompaniasNatural = 0;
		var contadorCompaniasCorporation = 0;
		for (var a in data) {
			var rowTemp = [];
			
			if (data[a].entityType=='natural') {
				data[a].entityType = 'Física'
				contadorCompaniasNatural++;
			}
			if (data[a].entityType=='corporation') {
				data[a].entityType = 'Jurídica'
				contadorCompaniasCorporation++
			}
			rowTemp = [data[a].createdBy,data[a].legalName,data[a].entityType,data[a].establecimientos,data[a].animals,data[a].country,data[a].state,data[a].city,data[a].createdAt,data[a].tin]
			dataToTable.push(rowTemp)
			if (data[a].establecimientos==0) {
				contadorCompaniasSinEstablecimiento++;
			}
		}
		var titulosToTable = ['Creado Por','Nombre','Tipo Persona','Total Establecimientos', 'Total Animales Activos','Pais','Provincia','Localidad','Fecha Creación', 'CUIT']
		
		this.pendingCompanies = false;
		
		this.setState({
            companies:
            {
                count:data.length,
                dataToTable:{data:dataToTable,titles:titulosToTable,showTitle:false}
            }
		});
		this.setState(
			{companiesWithoutFacilities:contadorCompaniasSinEstablecimiento,companiesNatural:contadorCompaniasNatural,companiesCorporation:contadorCompaniasCorporation}
		);
		
		eval("$('#cv-table-companies').DataTable({'paging'      : true,'lengthChange': false,'searching'   : true,'ordering'    : true,'info'        : true,'autoWidth'   : false});");
        
	}

	bindSurrogates(data) {		
		var dataToTable = [];		
		for (var a in data) {
			var rowTemp = [];						
			rowTemp = [data[a].companyLegalName,data[a].companyEmail,data[a].surrogateName,data[a].surrogateEmail]
			dataToTable.push(rowTemp)			
		}
		var titulosToTable = ['Nombre Companía','Email Companía','Nombre Delegado','Email Delegado']
		
		this.pendingSurrogates = false;
		
		this.setState({
            surrogates:
            {
                count:data.length,
                dataToTable:{data:dataToTable,titles:titulosToTable,showTitle:false}
            }
		});		
		
		
        eval("$('#cv-table-surrogates').DataTable({'paging'      : true,'lengthChange': false,'searching'   : true,'ordering'    : true,'info'        : true,'autoWidth'   : false});");
        
	}

	bindFacilities(data) {		
		var dataToTable = [];
		let groupByType = {};
		for (var a in data) {			
			var rowTemp = [];
			rowTemp = [
				data[a].createdBy,
				data[a].name,
				data[a].animals,
				data[a].company,
				data[a].type,
				data[a].renspaCode,								
				data[a].surfaceArea,
				data[a].country,
				data[a].state,
				data[a].city,
				data[a].createdAt,
				(data[a].mikrositoUrl!='')?data[a].mikrositoUrl:'-',
			]
			if (isNaN(groupByType[data[a].type])) {
				groupByType[data[a].type]=0;
			}
			groupByType[data[a].type]++;
			dataToTable.push(rowTemp)
		}
		var arrayGroupByType = [];
		for (var type in groupByType) {
			arrayGroupByType.push({'type':type,'count':groupByType[type]});
		}		
		
		
		var titulosToTable = [
			'Creado Por',
			'Nombre',
			'Tokens / Animales',
			'Compania',
			'Tipo',
			'RENSPA',
			'Superficie',
			'Pais',
			'Provincia',
			'Localidad',
			'Fecha Creación',			
			'Mikrosito Activo',			
		]
		
		this.pendingFacilities = false;
		
		this.setState({
            facilities:
            {
                count:data.length,
                dataToTable:{data:dataToTable,titles:titulosToTable,showTitle:false}
            }
		});
		
		this.setState({
			facilitiesGroupByType: arrayGroupByType
		});
		
		
		eval("$('#cv-table-facilities').DataTable({'paging'      : true,'lengthChange': false,'searching'   : true,'ordering'    : true,'info'        : true,'autoWidth'   : false});");
        
	}

	bindUsers(data) {		
		var dataToTable = [];
		var contadorUsuariosSinTokens = 0;
		for (var a in data) {
			var rowTemp = [];
			if (data[a].emailVerified) {
				data[a].emailVerified='SI'
			} else {
				data[a].emailVerified='NO'
			}
			if (data[a].fullRegister) {
				data[a].fullRegister='SI'
			} else {
				data[a].fullRegister='NO'
			}
			rowTemp = [data[a].user,data[a].emailVerified,data[a].fullRegister,data[a].animals,data[a].country,data[a].state,data[a].city,data[a].createdAt]
			dataToTable.push(rowTemp)
			if (data[a].animals==0) {
				contadorUsuariosSinTokens++;
			}
		}
		var titulosToTable = ['Usuario','Email Verificado','Full Register','Tokens / Animales','Pais','Estado','Localidad','Fecha Creacion']
		
		this.pendingUsers = false;
		
		this.setState({
            users:
            {
                count:data.length,
                dataToTable:{data:dataToTable,titles:titulosToTable,showTitle:false}
            }
		});
		this.setState(
			{usersWithoutTokens:contadorUsuariosSinTokens}
		);
		
		
		eval("$('#cv-table-users').DataTable({'paging'      : true,'lengthChange': false,'searching'   : true,'ordering'    : true,'info'        : true,'autoWidth'   : false});");
        
	}

	bindEstablecimientos(data) {		
		
		
		var dataToTable = [];
		for (var a in data) {
			var rowTemp = [];
			rowTemp = [data[a].compania,data[a].establecimiento,data[a].animales,data[a].eventos]
			dataToTable.push(rowTemp)
		}
		var titulosToTable = ['Companía','Establecimiento','Animales','Eventos']
		this.setState({establecimientosToTable:{data:dataToTable,titles:titulosToTable,showTitle:false}})
		
		
		this.setState({establecimientos:data})
		this.setState({establecimientos_count:data.length})
		var cantidadEventos = 0;
		for (var a in data) {
			cantidadEventos+=data[a].eventos;
		}
		
		
		this.setState({eventos_count:cantidadEventos})
	}

	linkRef = React.createRef();
	render() {
		const position = [this.state.lat, this.state.lng] 
		return (		   

		<div class="content-wrapper">		
			<a ref={this.linkRef}/>
			<DashHeader actualPage="Tablero de Control" onInitButtonHeader={this.init.bind(this)} onLogoutButtonHeader={this.logout.bind(this)}/>

			<section class="content">      
			<div class="container-fluid">
			
		  
		  <div>
		   {(this.state.logged==false) && (
			<div>
			Usuario <input type="textbox" onChange={this.loginUserChange.bind(this)}></input>&nbsp;
			Password <input type="password" onChange={this.loginPasswordChange.bind(this)}></input>&nbsp;<button onClick={this.login.bind(this)}>Ingresar</button>
			</div>
		   )}
		   {(this.state.logged) && (
			<div>
			{(false) && (
			<div class="row">
				<div class="col-12 col-sm-6 col-md-3">
					<div class="form-group pull-right">
						<label>Filtro:</label>
						<div class="input-group">
							<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="far fa-calendar-alt"></i>
							</span>
							</div>
							<input type="text" class="form-control float-right" id="filtro_rango_fechas"/>
							<span class="input-group-append">
								<button type="button" class="btn btn-info btn-flat">Filtrar</button>
							</span>
						</div>					
					</div>
				</div>
			</div>)}
			{(true) && (
			<div class="row">
				<div class="col-12 col-sm-6 col-md-3">
					<div class="form-group pull-right">
						
						<div class="input-group">
							<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="fa fa-search"></i>
							</span>
							</div>
							<input type="text" ref={this.filterEmail} class="form-control float-right"/>
							<span class="input-group-append">
								<button type="button" class="btn btn-info btn-flat" onClick={this.init.bind(this)}>Filtrar</button>
								&nbsp;<button type="button" class="btn btn-primary btn-flat" onClick={this.resetFilter.bind(this)}>Todos</button>
							</span>
						</div>					
					</div>
				</div>
			</div>)}
			{(true) &&            
			<div>
				
				<div class="row">

					<div class="col-12 col-sm-12 col-md-12">
							
							<div class="card collapsed-card">
							<div class="card-header border-transparent">
								<h3 class="card-title">

									<div class="info-box" style={{padding: '0px',marginBottom:'0px',boxShadow:'none'}}>
										<span class="info-box-icon bg-info elevation-1">
											<i class="fas fa-key"></i>
										</span>
										<div class="info-box-content">
											<span class="info-box-text">												
												<h4>Tokens / Animales
												&nbsp;<small data-card-widget="collapse" style={{fontSize:'12px',color:'blue'}}>(ver detalles)</small>
												&nbsp;<button type="button" onClick={() => this.exportarExcel('animals')} class="btn btn-sm btn-info">Descargar</button>
												</h4>
											</span>
											<span class="info-box-number">
												<h4>Total: 
												{(this.pendingAnimals) &&
												<img style={{width:"50px"}} src="https://app.carnesvalidadas.com/img/cow-transparent.gif"/>
												}
												{(!this.pendingAnimals) &&
												<b>{this.state.animals.count}</b>
												}
													
												</h4><small> </small>
											</span>
										</div>
									</div>


								</h3>

								<div class="card-tools">
								<button type="button" class="btn btn-tool" data-card-widget="collapse">
									<i class="fas fa-plus"></i>
								</button>							
								</div>
							</div>
						
							{(this.showAnimals) && 
							<div class="card-body p-0">
								
									<DashTable id="animals" key={this.childKeyAnimals} showTitle={this.state.animals.showTitle} titulo='' titulos={this.state.animals.dataToTable.titles} data={this.state.animals.dataToTable.data}/>					
								
							</div>
							}
						
							</div>
						
						</div>

				</div>
				
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12">
							
							<div class="card collapsed-card">
							<div class="card-header border-transparent">
								<h3 class="card-title">

									<div class="info-box" style={{padding: '0px',marginBottom:'0px',boxShadow:'none'}}>
										<span class="info-box-icon bg-warning elevation-1">
											<i class="fas fa-building"></i>
										</span>
										<div class="info-box-content">
											<span class="info-box-text">												
												<h4>Companias 
												&nbsp;<small data-card-widget="collapse" style={{fontSize:'12px',color:'blue'}}>(ver detalles)</small>
												&nbsp;<button type="button" onClick={() => this.exportarExcel('companies')} class="btn btn-sm btn-info">Descargar</button>
												</h4>
											</span>
											<span class="info-box-number">
												<h4>
													Total: 
													{(this.pendingCompanies) &&
													<img style={{width:"50px"}} src="https://app.carnesvalidadas.com/img/cow-transparent.gif"/>
													}
													{(!this.pendingCompanies) &&
													<b>{this.state.companies.count}</b>
													}													
													&nbsp;<small> ( sin establecimientos: <b>{this.state.companiesWithoutFacilities}</b> ), ( P. Físicas: <b>{this.state.companiesNatural}</b>, P. Jurídicas: <b>{this.state.companiesCorporation}</b> )</small>
												</h4>
											</span>
										</div>
									</div>


								</h3>

								<div class="card-tools">
								<button type="button" class="btn btn-tool" data-card-widget="collapse">
									<i class="fas fa-plus"></i>
								</button>							
								</div>
							</div>
						
							<div class="card-body p-0">
								<DashTable id="companies" key={this.childKeyCompanies} showTitle={this.state.companies.showTitle} titulo='' titulos={this.state.companies.dataToTable.titles} data={this.state.companies.dataToTable.data}/>					
							</div>
						
							</div>
						
						</div>

				</div>

				<div class="row">
					<div class="col-12 col-sm-12 col-md-12">
							
							<div class="card collapsed-card">
							<div class="card-header border-transparent">
								<h3 class="card-title">

									<div class="info-box" style={{padding: '0px',marginBottom:'0px',boxShadow:'none'}}>
										<span class="info-box-icon bg-info elevation-1">
											<i class="fas fa-arrow-right"></i>
										</span>
										<div class="info-box-content">
											<span class="info-box-text">												
												<h4>Delegaciones (Companías)
												&nbsp;<small data-card-widget="collapse" style={{fontSize:'12px',color:'blue'}}>(ver detalles)</small>
												&nbsp;<button type="button" onClick={() => this.exportarExcel('surrogates')} class="btn btn-sm btn-info">Descargar</button>
												</h4>
											</span>                                        
											<span class="info-box-number">
												<h4>
													Total: 
													{(this.pendingSurrogates) &&
													<img style={{width:"50px"}} src="https://app.carnesvalidadas.com/img/cow-transparent.gif"/>
													}
													{(!this.pendingSurrogates) &&
													<b>{this.state.surrogates.count}</b>											
													}
													
												</h4>
											</span>
										</div>									
									</div>
								</h3>

								<div class="card-tools">
								<button type="button" class="btn btn-tool" data-card-widget="collapse">
									<i class="fas fa-plus"></i>
								</button>							
								</div>
							</div>
						
							<div class="card-body p-0">
								<DashTable id="surrogates" key={this.childKeySurrogates} showTitle={this.state.surrogates.showTitle} titulo='' titulos={this.state.surrogates.dataToTable.titles} data={this.state.surrogates.dataToTable.data}/>					
							</div>
						
							</div>
						
						</div>

				</div>

				<div class="row">
					<div class="col-12 col-sm-12 col-md-12">
							
							<div class="card collapsed-card">
							<div class="card-header border-transparent">
								<h3 class="card-title">
									
									<div class="info-box" style={{padding: '0px',marginBottom:'0px',boxShadow:'none'}}>
										<span class="info-box-icon bg-success elevation-1">
											<i class="fas fa-map-marker"></i>
										</span>
										<div class="info-box-content">
											<span class="info-box-text">												
												<h4>Establecimientos
												&nbsp;<small data-card-widget="collapse" style={{fontSize:'12px',color:'blue'}}>(ver detalles)</small>
												&nbsp;<button type="button" onClick={() => this.exportarExcel('facilities')} class="btn btn-sm btn-info">Descargar</button>
												</h4>
											</span>
											<span class="info-box-number">
												<h4>Total: 
													{(this.pendingFacilities) &&
													<img style={{width:"50px"}} src="https://app.carnesvalidadas.com/img/cow-transparent.gif"/>
													}
													{(!this.pendingFacilities) &&
													<b>{this.state.facilities.count}</b>
													}													
												&nbsp;
												<small>
													(&nbsp;													
														{parse(this.state.facilitiesGroupByType.map(function(type) {
															return type.type+': <b>'+type.count+'</b>'	
													}).join(', '))}
												&nbsp;)</small>
												</h4>											
											</span>
										</div>
									</div>


								</h3>

								<div class="card-tools">
								<button type="button" class="btn btn-tool" data-card-widget="collapse">
									<i class="fas fa-plus"></i>
								</button>							
								</div>
							</div>
						
							<div class="card-body p-0">
								<DashTable id="facilities" key={this.childKeyFacilities} showTitle={this.state.facilities.showTitle} titulo='' titulos={this.state.facilities.dataToTable.titles} data={this.state.facilities.dataToTable.data}/>					
							</div>
						
							</div>
						
						</div>

				</div>

				<div class="row">
					<div class="col-12 col-sm-12 col-md-12">
							
							<div class="card collapsed-card">
							<div class="card-header border-transparent">
								<h3 class="card-title">
									
									<div class="info-box" style={{padding: '0px',marginBottom:'0px',boxShadow:'none'}}>
										<span class="info-box-icon bg-danger elevation-1">
											<i class="fas fa-users"></i>
										</span>
										<div class="info-box-content">
											<span class="info-box-text">												
												<h4>Usuarios
												&nbsp;<small data-card-widget="collapse" style={{fontSize:'12px',color:'blue'}}>(ver detalles)</small>
												&nbsp;<button type="button" onClick={() => this.exportarExcel('users')} class="btn btn-sm btn-info">Descargar</button>
												</h4>
											</span>
											<span class="info-box-number">
												<h4>Total: 
													{(this.pendingUsers) &&
													<img style={{width:"50px"}} src="https://app.carnesvalidadas.com/img/cow-transparent.gif"/>
													}
													{(!this.pendingUsers) &&
													<b>{this.state.users.count}</b>
													}
													
												&nbsp;
												<small> ( sin tokens / animales: <b>{this.state.usersWithoutTokens}</b> )</small>
												</h4>											
											</span>
										</div>
									</div>


								</h3>

								<div class="card-tools">
								<button type="button" class="btn btn-tool" data-card-widget="collapse">
									<i class="fas fa-plus"></i>
								</button>							
								</div>
							</div>
						
							<div class="card-body p-0">
								<DashTable id="users" key={this.childKeyUsers} showTitle={this.state.users.showTitle} titulo='' titulos={this.state.users.dataToTable.titles} data={this.state.users.dataToTable.data}/>					
							</div>
						
							</div>
						
						</div>

				</div>

				<div class="row">
					<div class="col-12 col-sm-12 col-md-12">
							
							<div class="card collapsed-card">
							<div class="card-header border-transparent">
								<h3 class="card-title">
									
									<div class="info-box" style={{padding: '0px',marginBottom:'0px',boxShadow:'none'}}>
										<span class="info-box-icon bg-info elevation-1">
											<i class="fas fa-clipboard-check"></i>
										</span>
										<div class="info-box-content">
											<span class="info-box-text">
												<h4>Eventos											
												</h4>
											</span>
											<span class="info-box-number">
												<h4>Total: <b>{this.state.eventsCount}</b>
												&nbsp;											
												</h4>											
											</span>
										</div>											
									</div>
									


								</h3>
								<div class="card-tools" style={{float:'left'}}>
								<small style={{fontSize:'18px'}}>									
											{parse(this.state.eventsGroupByType.map(function(type) {
												return '<span class="badge badge-secondary"><font style="weight:normal;">'+type.type+':</style> <b>'+type.count+'</b></span>'	
										}).join(',<br/>'))}
									</small>
								</div>
								
								
							</div>
							

							</div>
						
						</div>

				</div>
				{((false) && 
				<div class="row">
					<div class="col-12 col-sm-6 col-md-3">
						<DashInfoBox value={this.state.animales_count} icon="fa-key" class="bg-info" title="Tokens / Animales"/>
					</div>				

					<div class="clearfix hidden-md-up"></div>

					<div class="col-12 col-sm-6 col-md-3">              
						<DashInfoBox value={this.state.establecimientos_count} icon="fa-map-marker" class="bg-success" title="Establecimientos Activos"/>			                    
					</div>
					
					<div class="col-12 col-sm-6 col-md-3">              
						<DashInfoBox value={this.state.eventos_count} icon="fa-file-import" class="bg-warning" title="Eventos"/>			                    				              
					</div>
					
				</div>
				)}
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12">

						
						
						</div>
						<div class="col-12 col-sm-12 col-md-12">
							<div>
								<div class="card">
								<div class="card-header border-transparent">
									<h3 class="card-title">Ubicación de los Establecimientos</h3>

									<div class="card-tools">
									<button type="button" class="btn btn-tool" data-card-widget="collapse">
										<i class="fas fa-minus"></i>
									</button>
									<button type="button" class="btn btn-tool" data-card-widget="remove">
										<i class="fas fa-times"></i>
									</button>
									</div>
								</div>
							
								<div class="card-body p-0">
								
								<Map center={position} zoom={this.state.zoom} style={{height : '400px'}}>
									<TileLayer
									attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>
									{this.state.establecimientos.map(establecimiento => (
										<Marker position={establecimiento.location.coordinates}>
										<Popup>
											{establecimiento.establecimiento}
										</Popup>
										</Marker>		        		
									))}																
								</Map>

								</div>
								</div>
							</div>							
						</div>
				</div>


				<div class="row">
					<div class="col-12 col-sm-12 col-md-12">
							
							<div class="card collapsed-card">
							<div class="card-header border-transparent">
								<h3 class="card-title">
									
									<div class="info-box" style={{padding: '0px',marginBottom:'0px',boxShadow:'none'}}>
										<span class="info-box-icon bg-info elevation-1">
											<i class="fas fa-clipboard-check"></i>
										</span>
										<div class="info-box-content">
											<span class="info-box-text">
												<h4>Descargas											
												</h4>
											</span>											
										</div>											
									</div>
									<br/>
									<h4>Tables FULL</h4>
									<button class="btn btn-sm btn-info" onClick={()=> this.fullExcel()}>Descargar Todas las Tablas de la Base de Datos</button>
									<br/>
									<br/>
									<h4>Métricas</h4>
									Fecha Corte <input ref={this.fechaCorteMetricas} type="textbox" placeholder="YYYY-MM-DD"></input>
									<br/><br/>
									<button class="btn btn-sm btn-info" onClick={() => this.metricas()}>Descargar Métricas</button>

								</h3>	
							</div>
							
							</div>						
						</div>

				</div>


				<div class="modal fade" id="wait" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="myModalLabel"><i class="fa fa-clock-o"></i> Aguarde...</h4>
					</div>					
					</div>
				</div>
				</div>



				</div>
				}
				</div>
				)}
			</div>

			</div>
			</section>
			
		</div>
		
		 );
		}	
}
export default DashContainerFull
